import React from 'react';
import { StarIcon as FilledStarIcon } from '@heroicons/react/24/solid';
import { StarIcon as OutlineStarIcon } from '@heroicons/react/24/outline';

interface ReviewItemProps {
    title: string;
    rating: number;
    comment: string;
    isLast: boolean;
    onReport: () => void;
}

const ReviewItem: React.FC<ReviewItemProps> = ({ title, rating, comment, isLast, onReport }) => {
    return (
        <li className={`py-8 relative ${!isLast ? 'border-b border-x-secondaryLight' : ''}`}>
            <button className="font-bold text-sm text-secondaryLight absolute right-0 top-8" onClick={onReport}>
                Report
            </button>
            <div className="px-3">
                <div className="flex items-center mb-0">
                    <h4 className="text-md font-bold mr-3">{title}</h4>
                    <div className="stars flex">
                        {[...Array(5)].map((_, i) => (
                            i < rating ? (
                                <FilledStarIcon key={i} className="w-4 text-yellow-300" />
                            ) : (
                                <OutlineStarIcon key={i} className="w-4 text-yellow-300" />
                            )
                        ))}
                    </div>
                </div>
                <div>
                    <span className="text-sm font-semibold">{rating}.0 / 5.0</span>
                </div>
                <div className="mt-2">
                    <p className="font-light text-sm">{comment}</p>
                </div>
            </div>
        </li>
    );
};

export default ReviewItem;
