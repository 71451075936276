import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeroBanner from '../../components/common/headsections/HeroBanner';
import Filter from '../../components/filters/Filter';
import PrimaryGrid from '../../components/common/PrimaryGrid';
import { RootState, AppDispatch } from '../../store';
import { fetchJobs, setFilters } from '../../features/jobs/jobSlice';
import defaultImg2 from '../../assets/images/default-grid.png';

const cities = [
    { value: '', name: 'All Locations' },
    ...[
        'Auckland', 'Wellington', 'Christchurch', 'Hamilton', 'Tauranga', 'Napier-Hastings',
        'Dunedin', 'Palmerston North', 'Nelson', 'Rotorua', 'New Plymouth', 'Whangarei',
        'Invercargill', 'Whanganui', 'Gisborne', 'Upper Hutt', 'Masterton', 'Timaru',
        'Blenheim', 'Taupo', 'Pukekohe', 'Cambridge', 'Queenstown', 'Levin'
    ].map(city => ({ value: city, name: city }))
];

const categories = [
    { value: '', name: 'All Categories' },
    ...[
        'Painting Jobs',
        'Interior Work',
        'Exterior Work',
        'Roofing',
        'Landscaping',
        'Plumbing'
    ].map(category => ({ value: category, name: category }))
];

const budgets = [
    { value: '0,10000000', name: 'Any Budget' },
    { value: '0,1000', name: '$0 ~ $1000' },
    { value: '1000,10000', name: '$1000 ~ $10000' },
    { value: '10000,20000', name: '$10000 ~ $20000' },
];

const AvailableJobs: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { jobs, totalPages, currentPage, filters, status, error } = useSelector(
        (state: RootState) => state.jobs
    );

    const handleFilterChange = (filterType: string, value: any) => {
        const updatedFilters = {
            ...filters,
            [filterType]: value,
        };
        dispatch(setFilters(updatedFilters));
        dispatch(fetchJobs(1));
    };

    const handlePageChange = (page: number) => {
        dispatch(fetchJobs(page));
    };

    useEffect(() => {
        dispatch(fetchJobs(currentPage));
    }, [dispatch, currentPage]);

    const transformedJobs = jobs ? jobs.map(job => ({
        title: job.title,
        description: job.description,
        imageUrl: job.images[0] || defaultImg2,
        slug: job.slug
    })) : [];

    return (
        <div className="font-sans">
            <HeroBanner />
            <div className="container mx-auto px-4 lg:px-18 py-6">
                <section id="filters">
                    <h4 className="font-bold text-sm">FILTER</h4>
                    <div className="flex space-x-4">
                        <Filter
                            label="Location"
                            options={cities}
                            selectedOption={cities.find(city => city.value === filters.location) || cities[0]}
                            onChange={(option) => handleFilterChange('location', option.value)}
                        />
                        <Filter
                            label="Category"
                            options={categories}
                            selectedOption={categories.find(category => category.value === filters.category) || categories[0]}
                            onChange={(option) => handleFilterChange('category', option.value)}
                        />
                        <Filter
                            label="Budget"
                            options={budgets}
                            selectedOption={budgets.find(budget => budget.value === filters.budget) || budgets[0]}
                            onChange={(option) => handleFilterChange('budget', option.value)}
                        />
                    </div>
                </section>

                <section id="listings" className="mt-10">
                    <h4 className="font-black text-sm mb-6">AVAILABLE JOBS - {jobs ? jobs.length : 0} Listings</h4>
                    {status === 'loading' ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p className="text-red-600">{error}</p>
                    ) : (
                        <PrimaryGrid list={transformedJobs} />
                    )}
                    <div className="mt-20"></div>
                    <div className="text-secondaryLight text-center text-sm font-extrabold tracking-[1em]">
                        {[...Array(totalPages)].map((_, index) => (
                            <span
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={`cursor-pointer ${index + 1 === currentPage ? 'text-primary' : ''}`}
                            >
                                {index + 1}
                            </span>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default AvailableJobs;
