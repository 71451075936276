import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import config from '../../config/config';

interface Reviewer {
    id: number;
    username: string;
}

interface Review {
    id: number;
    rating: number;
    comment: string;
    reviewerId: number;
    revieweeId: number;
    projectId: number;
    createdAt: string;
    updatedAt: string;
    reviewer: Reviewer;
}

interface ReviewsState {
    reviews: Review[];
    totalReviews: number;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    submitStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    submitError: string | null;
    successMessage: string | null;
}

const initialState: ReviewsState = {
    reviews: [],
    totalReviews: 0,
    status: 'idle',
    error: null,
    submitStatus: 'idle',
    submitError: null,
    successMessage: null,
};

export const fetchReviews = createAsyncThunk(
    'reviews/fetchReviews',
    async ({ userId, projectId }: { userId?: number; projectId?: number }, { rejectWithValue }) => {
        try {
            const queryParam = userId ? `userId=${userId}` : `projectId=${projectId}`;
            const response = await axios.get(`${config.apiBaseUrl}/reviews?${queryParam}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            return rejectWithValue(axiosError.response?.data || 'An error occurred');
        }
    }
);

export const submitReview = createAsyncThunk(
    'reviews/submitReview',
    async (
        { projectId, rating, comment }: { projectId: number; rating: number; comment: string },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(
                `${config.apiBaseUrl}/reviews`,
                { projectId, rating, comment },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            return rejectWithValue(axiosError.response?.data || 'An error occurred');
        }
    }
);

const reviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {
        clearSubmitStatus: (state) => {
            state.submitStatus = 'idle';
            state.submitError = null;
            state.successMessage = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch Reviews
            .addCase(fetchReviews.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchReviews.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reviews = action.payload.reviews;
                state.totalReviews = action.payload.totalReviews;
            })
            .addCase(fetchReviews.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            // Submit Review
            .addCase(submitReview.pending, (state) => {
                state.submitStatus = 'loading';
                state.submitError = null;
            })
            .addCase(submitReview.fulfilled, (state, action) => {
                state.submitStatus = 'succeeded';
                state.successMessage = 'Review created successfully!';
                state.reviews.push(action.payload.review); // Add the new review to the list
            })
            .addCase(submitReview.rejected, (state, action) => {
                state.submitStatus = 'failed';
                state.submitError = action.payload as string;
            });
    },
});

export const { clearSubmitStatus } = reviewsSlice.actions;
export default reviewsSlice.reducer;
