import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import config from '../../config/config';

interface Job {
  title: string;
  category: string;
  location: string;
  budget: string;
  description: string;
}

interface JobResponse {
  id: number;
  title: string;
  slug: string;
  category: string;
  location: {
    city: string;
    country: string;
  };
  budget: string;
  description: string;
  status: string;
  specialInstructions: string;
  images: string[];
  createdAt: string;
  updatedAt: string;
}

interface JobListResponse {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  projects: JobResponse[];
}

interface JobState {
  job: JobResponse | null;
  jobs: JobResponse[];
  totalPages: number;
  currentPage: number;
  filters: {
    location: string;
    category: string;
    budget: string;
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

interface JobSuccessResponse {
  message: string;
  project: JobResponse;
}

interface JobErrorResponse {
  error: string;
}

// Thunk to post a job
export const postJob = createAsyncThunk(
  'jobs/postJob',
  async (jobData: FormData, thunkAPI) => {
    try {
      const response = await axios.post<JobSuccessResponse>(
        `${config.apiBaseUrl}/projects`,
        jobData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<JobErrorResponse>;
        return thunkAPI.rejectWithValue(axiosError.response?.data?.error || 'An error occurred');
      }
      return thunkAPI.rejectWithValue('An unknown error occurred');
    }
  }
);

// Thunk to fetch jobs with filters and pagination
export const fetchJobs = createAsyncThunk(
  'jobs/fetchJobs',
  async (page: number, { getState, rejectWithValue }) => {
    const state = getState() as { jobs: JobState };
    const { location, category, budget } = state.jobs.filters;

    try {
      const response = await axios.get<JobListResponse>(`${config.apiBaseUrl}/projects`, {
        params: {
          location,
          category,
          budget,
          page,
          limit: 8,
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<JobErrorResponse>;
        return rejectWithValue(axiosError.response?.data?.error || 'Failed to fetch jobs');
      }
      return rejectWithValue('An unknown error occurred');
    }
  }
);

const initialState: JobState = {
  job: null,
  jobs: [],
  totalPages: 1,
  currentPage: 1,
  filters: {
    location: '',
    category: '',
    budget: ''
  },
  status: 'idle',
  error: null,
};

const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters(state) {
      state.filters = initialState.filters;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postJob.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(postJob.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.job = action.payload.project;
      })
      .addCase(postJob.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(fetchJobs.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobs = action.payload.projects;
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { setFilters, resetFilters } = jobSlice.actions;
export default jobSlice.reducer;
