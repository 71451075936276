import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import HireNow from './HireNow';
import companyImg from './../../assets/images/company.png';
import config from '../../config/config';

const stripePromise = loadStripe(config.stripeKey);

interface Bidder {
    id: number;
    username: string;
    email: string;
    contactNumber: string;
    rating: number;
    businessDetails: {
        legalBusinessName: string;
        nzbn: string;
    };
}

interface Bid {
    id: number;
    description: string;
    price: number;
    currency: string;
    quantity: number;
    totalAmount: number;
    gst: number;
    projectId: number;
    userId: number;
    createdAt: string;
    updatedAt: string;
    bidder: Bidder;
}

interface BidItemProps {
    bid: Bid;
    projectOwnerId: number;
    user: { id: number; userType: string } | null;
    hiredBidderId: number | null;
}

const BidItem: React.FC<BidItemProps> = ({ bid, projectOwnerId, user, hiredBidderId }) => {
    const [isHireNowOpen, setHireNowOpen] = useState(false);
    const navigate = useNavigate();

    const handleHireNowClick = () => {
        if (!user) {
            navigate('/sign-in');
        } else {
            setHireNowOpen(true);
        }
    };

    return (
        <li className={"py-4 relative border-b border-x-secondaryLight last:border-none "}>
            < div className="flex justify-between items-start">
                <div className="flex flex-shrink-0 mr-4 items-start">
                    <Link to={`/companies/${bid.bidder.username}`}>
                        <img src={companyImg} className="h-20 w-20 shadow-sm object-cover rounded-md" alt="Company" />
                    </Link>
                </div>
                <div className="flex-grow">
                    <div className="flex items-center mb-0">
                        <h4 className="text-md font-bold mr-3">
                            <Link to={`/companies/${bid.bidder.username}`}>{bid.bidder.businessDetails.legalBusinessName}</Link>
                            {(hiredBidderId && hiredBidderId === bid.userId) && (<span className="font-normal ml-3 text-secondary">(Hired)</span>)}
                        </h4>
                    </div>
                    <div>
                        <span className="text-sm font-semibold">{bid.bidder.rating ? (<p className="text-light font-medium text-md">{Number(bid.bidder.rating).toFixed(1) + ' / 5.0'}</p>) : ''}</span>
                    </div>
                    <div>
                        <span className="text-xs font-semibold text-secondaryLight">
                            Bid price: ${bid.totalAmount}
                        </span>
                    </div>
                    <div className="mt-2 max-h-16 overflow-hidden text-ellipsis">
                        <p className="font-light text-sm">{bid.description}</p>
                    </div>
                </div>

                {(user?.userType === 'normal' && user.id === projectOwnerId) || !user ? (
                    <div className="flex flex-col space-y-2 ml-4 flex-shrink-0">
                        {!hiredBidderId && (
                            <button
                                onClick={handleHireNowClick}
                                className="w-40 py-2 bg-primary text-white font-medium text-sm rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                            >
                                Hire Now
                            </button>
                        )}

                        <button
                            onClick={() => navigate('/contact')}
                            className="w-40 py-2 bg-primary text-white font-medium text-sm rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                        >
                            Contact
                        </button>


                    </div>
                ) : null}
            </div>

            {
                isHireNowOpen && (
                    <Elements stripe={stripePromise}>
                        <HireNow isOpen={isHireNowOpen} onClose={() => setHireNowOpen(false)} bid={bid} />
                    </Elements>
                )
            }
        </li >
    );
};

export default BidItem;
