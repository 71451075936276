import React from 'react';
import banner from '../../../assets/images/home/banner.png';
import { CSSProperties } from 'react';

const heroBannerStyle: CSSProperties = {
    position: 'relative' as const,
    backgroundImage: `url(${banner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    color: 'white',
};

const overlayStyle: CSSProperties = {
    position: 'absolute' as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: 1,
};

const contentStyle: CSSProperties = {
    position: 'relative' as const,
    zIndex: 2,
};

interface Props {
    title?: string,
    subTitle?: string
}

const DefaultTitle: React.FC<Props> = ({ title, subTitle }) => {
    return (
        <section id="hero-banner" style={heroBannerStyle}>
            <div style={overlayStyle}></div>
            <div className="container mx-auto px-4 lg:px-8" style={contentStyle}>
                <div className="py-12">
                    <h3 className="font-semibold text-white text-3xl">
                        {title}
                    </h3>
                    <div className="mt-2">
                        <p className="font-light text-white text-sm">{subTitle}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DefaultTitle;
