import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { fetchUserProjects } from '../../features/projects/projectSlice';
import { Link } from 'react-router-dom';

interface ProjectListProps {
    username: string;
}

const ProjectList: React.FC<ProjectListProps> = ({ username }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { projects, user, status, error } = useSelector((state: RootState) => state.projects);

    useEffect(() => {
        if (username) {
            dispatch(fetchUserProjects(username));
        }
    }, [username, dispatch]);

    if (status === 'loading') return <p>Loading...</p>;
    if (error) return <p className="text-red-600">{error}</p>;

    return (
        <div className="space-y-2">
            {projects.map(project => (
                <div key={project.id} className="flex justify-between items-center rounded-2xl shadow-md px-8 py-4">
                    <div>
                        {user?.userType === "business" && (<h5 className="text-secondaryLight text-sm font-medium">{project.user.username}</h5>)}
                        <Link to={`/jobs/${project.slug}`}><h4 className="text-md font-bold">{project.title}</h4></Link>
                    </div>
                    {user?.userType === "normal" ? (
                        <Link className="text-secondaryLight text-md" to={`/jobs/${project.slug}`}>
                            {`${project.bids.length} Bid${project.bids.length > 1 ? 's' : ''}`}
                        </Link>
                    ) : (
                        <Link className="text-secondaryLight text-md" to={`/jobs/${project.slug}`}>
                            {project.hiredBidderId ? 'Payment Completed' : 'Bidded'}
                        </Link>
                    )}


                </div>
            ))}
        </div>
    );
};

export default ProjectList;
