import React, { useState } from 'react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface ImageUploadProps {
  images: File[];
  onImageChange: (files: File[]) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ images, onImageChange }) => {
  const [selectedImages, setSelectedImages] = useState<File[]>(images);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const updatedImages = [...selectedImages, ...files];
      setSelectedImages(updatedImages);
      onImageChange(updatedImages);
    }
  };

  const removeImage = (index: number) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
    onImageChange(updatedImages);
  };

  return (
    <div className="flex items-center">
      <label className="shadow-md w-28 h-28 rounded-2xl flex items-center justify-center text-center cursor-pointer border border-dashed border-gray-300">
        <input type="file" multiple className="hidden" onChange={handleFileChange} />
        <PlusIcon className="w-6 h-6 text-secondary font-bold" />
      </label>
      <div className="flex ml-3 space-x-2">
        {selectedImages.map((image, index) => (
          <div key={index} className="relative w-20 h-20">
            <img src={URL.createObjectURL(image)} alt="Preview" className="w-full h-full object-cover rounded-md" />
            <button
              onClick={() => removeImage(index)}
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 -mt-1 -mr-1 hover:bg-red-600"
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUpload;
