import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBidsByProject } from '../../features/bids/bidsSlice';
import { RootState, AppDispatch } from '../../store';
import { useNavigate } from 'react-router-dom';
import BidItem from './BidItem';
import ReviewList from '../reviews/ReviewList';

interface BidListProps {
    projectId: number;
    projectOwnerId: number;
    hiredBidderId: number | null;
    showOnlyHired?: boolean;
}

const BidList: React.FC<BidListProps> = ({ projectId, projectOwnerId, hiredBidderId, showOnlyHired = false }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { bids, fetchStatus, fetchError } = useSelector((state: RootState) => state.bids);
    const { user } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        dispatch(fetchBidsByProject(projectId));
    }, [dispatch, projectId]);

    const handleButtonClick = (route: string) => {
        if (!user) {
            // Redirect to login if not logged in
            navigate('/sign-in');
        } else {
            navigate(route);
        }
    };



    const filteredBids = bids.filter(bid =>
        showOnlyHired ? bid.userId === hiredBidderId : bid.userId !== hiredBidderId
    );

    if (fetchStatus === 'loading') return <p>Loading...</p>;
    if (fetchError) return <p className="text-red-600">{fetchError}</p>;
    if (!filteredBids.length) return <p>No bids found</p>;

    return (
        <>
            <div className="space-y-2">
                <div className="rounded-2xl shadow-md px-8 py-4">
                    <ul>
                        {filteredBids.map(bid => (
                            <BidItem
                                key={bid.id}
                                bid={bid}
                                projectOwnerId={projectOwnerId}
                                hiredBidderId={hiredBidderId}
                                user={user}
                            />
                        ))}
                    </ul>
                </div>
            </div>
            {
                (showOnlyHired && filteredBids) && (
                    <div className="mt-8">
                        <h3 className="font-bold text-xl text-center mb-4">Project Reviews</h3>
                        <ReviewList projectId={filteredBids[0].projectId} />
                    </div >
                )
            }
        </>
    );
};

export default BidList;
