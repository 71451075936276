import React from 'react';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { uploadBusinessImage, deleteBusinessImage } from '../../../features/businessImages/businessImagesSlice';
import config from '../../../config/config';

interface ImageManagerProps {
    images: string[];
}

const ImageManager: React.FC<ImageManagerProps> = ({ images }) => {
    const dispatch = useDispatch<AppDispatch>();

    const handleAddImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            await dispatch(uploadBusinessImage(file)).unwrap();
            // No need to update local state; Redux will update the props
        }
    };

    const handleRemoveImage = async (image: string) => {
        await dispatch(deleteBusinessImage(image)).unwrap();
        // No need to update local state; Redux will update the props
    };

    return (
        <div className="flex items-center">
            <label className="shadow-md w-28 h-28 rounded-2xl flex items-center justify-center text-center cursor-pointer border border-dashed border-gray-300">
                <input type="file" className="hidden" onChange={handleAddImage} />
                <PlusIcon className="w-6 h-6 text-secondary font-bold" />
            </label>
            <div className="flex ml-3 space-x-2">
                {(images || []).map((image, index) => (
                    <div key={index} className="relative w-20 h-20">
                        <img src={`${config.imageBaseUrl}/${image}`} alt="Uploaded" className="w-full h-full object-cover rounded-md" />
                        <button
                            onClick={() => handleRemoveImage(image)}
                            className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 -mt-1 -mr-1 hover:bg-red-600"
                        >
                            <XMarkIcon className="w-4 h-4" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageManager;
