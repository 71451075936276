import React, { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import config from '../../config/config';

interface LightboxGalleryProps {
    images: string[];
}

const LightboxGallery: React.FC<LightboxGalleryProps> = ({ images }) => {
    const [index, setIndex] = useState(-1);

    if (!images || images.length === 0) return null;

    return (
        <div>
            <img
                src={`${config.imageBaseUrl}/${images[0]}`}
                alt="Main job image"
                className="w-full rounded-2xl object-cover h-96 cursor-pointer"
                onClick={() => setIndex(0)}
            />

            <div className="flex mt-4 space-x-4">
                {images.slice(1).map((img, i) => (
                    <img
                        key={i}
                        src={`${config.imageBaseUrl}/${img}`}
                        alt={`Thumbnail ${i + 1}`}
                        className="w-20 h-20 object-cover rounded cursor-pointer"
                        onClick={() => setIndex(i)}
                    />
                ))}
            </div>

            <Lightbox
                index={index}
                slides={images.map((src) => ({ src: `${config.imageBaseUrl}/${src}` }))}
                open={index >= 0}
                close={() => setIndex(-1)}
            />
        </div>
    );
};

export default LightboxGallery;
