import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ProjectList from '../../components/projects/ProjectList';
import ReviewList from '../../components/reviews/ReviewList';
import EditBusinessProfileModal from '../../components/common/user/EditBusinessProfileModal';
import ImageManager from '../../components/common/user/ImageManager';
import companyImg from './../../assets/images/company.png';
import LightboxGallery from '../../components/common/LightboxGallery';

const BusinessProfile: React.FC = () => {
    const user = useSelector((state: RootState) => state.auth.user);
    const [isEditing, setIsEditing] = useState(false);
    const [isManagingImages, setIsManagingImages] = useState(false);

    if (!user) return <p>Loading...</p>;

    const { username, rating, contactNumber, businessDetails } = user;

    return (
        <div className="font-sans">
            <div className="container mx-auto px-8 lg:px-18 py-10">
                <div className="grid grid-cols-12 gap-8">
                    <div className="col-span-4 flex flex-col items-center">

                        {
                            (user.businessDetails && user.businessDetails.images) ? (
                                <LightboxGallery images={user.businessDetails.images} />
                            ) : (
                                <>
                                    <img className="w-full rounded-2xl object-cover" src={companyImg} alt="Company" />
                                </>
                            )
                        }
                        <button
                            onClick={() => setIsManagingImages(!isManagingImages)}
                            className="bg-primary text-white py-1 px-4 rounded-lg mb-4 mt-10"
                        >
                            {isManagingImages ? 'Save Changes' : 'Manage Images'}
                        </button>
                        {(user.businessDetails && isManagingImages) && (
                            <ImageManager images={user.businessDetails.images || []} />
                        )}
                    </div>
                    <div className="col-span-8 flex">
                        <div className="shadow-md w-full p-8 rounded-2xl flex flex-col justify-between relative">
                            <div>
                                <h4 className="font-semibold mb-3">
                                    Company Name: <span className="font-normal">{businessDetails?.legalBusinessName || username}</span>
                                </h4>
                                <h4 className="font-semibold mb-3">
                                    Rating: <span className="font-normal">{rating ? `${rating} / 5.0` : 'No ratings yet'}</span>
                                </h4>
                                <h4 className="font-semibold mb-3">
                                    Location: <span className="font-normal">{businessDetails?.businessLocation || 'N/A'}</span>
                                </h4>
                                <h4 className="font-semibold mb-3">
                                    Contact Details: <span className="font-normal">{contactNumber}</span>
                                </h4>
                                <h4 className="font-semibold mb-3">
                                    Business Fields: <span className="font-normal">{businessDetails?.businessFields || 'N/A'}</span>
                                </h4>
                                <h4 className="font-semibold">
                                    NZBN: <span className="font-normal">{businessDetails?.nzbn || 'N/A'}</span>
                                </h4>
                            </div>
                            <button
                                onClick={() => setIsEditing(true)}
                                className="font-bold text-xs text-secondaryLight absolute right-8 top-8"
                            >
                                EDIT PROFILE
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-16"></div>
                <section id="current-projects">
                    <h3 className="font-bold text-xl text-center mb-6">Current Projects</h3>
                    {user && (<ProjectList username={user.username} />)}
                </section>

                <div className="mt-16"></div>
                <section id="current-projects">
                    <h3 className="font-bold text-xl text-center mb-6">Company Review</h3>
                    <ReviewList userId={user?.id} />
                </section>

                {isEditing && user && (
                    <EditBusinessProfileModal
                        user={user}
                        onClose={() => setIsEditing(false)}
                    />
                )}
            </div>
        </div >
    );
};

export default BusinessProfile;
