import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import DefaultTitle from '../../components/common/titles/DefaultTitle';
import blueTickMark from './../../assets/images/icons/blue-tickmark.png';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { registerUser } from '../../features/user/userSlice'; // Import the Redux action
import { RootState } from '../../store'; // Import RootState type for useSelector

// Define form data type
interface FormData {
    username: string;
    email: string;
    contactNumber: string;
    password: string;
    confirmPassword: string;
}

const Register: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // Selecting user state from the Redux store
    const { status, error } = useAppSelector((state: RootState) => state.user);

    // Initial form data state
    const [formData, setFormData] = useState<FormData>({
        username: '',
        email: '',
        contactNumber: '',
        password: '',
        confirmPassword: '',
    });

    const [isOpen, setIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Handle form input changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Handle form submission
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Basic form validation for matching passwords
        if (formData.password !== formData.confirmPassword) {
            setErrorMessage('Passwords do not match!');
            return;
        }

        // Dispatch the registerUser action using Redux
        dispatch(registerUser({
            username: formData.username,
            email: formData.email,
            contactNumber: formData.contactNumber,
            password: formData.password,
            confirmPassword: formData.confirmPassword
        })).then((result: unknown) => {
            if (registerUser.fulfilled.match(result)) {
                setIsOpen(true); // Open modal if registration succeeds
                setErrorMessage(''); // Clear error message if successful
            } else if (registerUser.rejected.match(result)) {
                setErrorMessage(result.payload as string); // Display error message from API response
            }
        });
    };

    // Navigate to the profile page after successful registration
    const goToProfile = () => {
        setIsOpen(false); // Close the modal
        navigate('/profile'); // Navigate to the profile page
    };

    return (
        <div className="font-sans">
            {/* Page Title */}
            <DefaultTitle
                title="Create a Damoa account"
                subTitle="Create your account and work with a variety of professionals."
            />

            <div className="container mx-auto px-8 lg:px-18 py-6">
                <div className="grid grid-cols-2">
                    <div>
                        {errorMessage && <p className="text-red-500 mb-6">Error: {errorMessage}</p>}
                        <form onSubmit={handleSubmit} className="w-full">
                            <div className="mb-4">
                                <label htmlFor="username" className="block text-md mb-2 font-medium">Username</label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-md mb-2 font-medium">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="contactNumber" className="block text-md mb-2 font-medium">Contact number</label>
                                <input
                                    type="text"
                                    id="contactNumber"
                                    name="contactNumber"
                                    value={formData.contactNumber}
                                    onChange={handleChange}
                                    className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="password" className="block text-md mb-2 font-medium">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="confirmPassword" className="block text-md mb-2 font-medium">Confirm Password</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                />
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                                >
                                    {status === 'loading' ? 'Creating...' : 'Create account'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Success Dialog Modal */}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
                <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                    <div className="text-center">
                        <h2 className="text-xl font-extrabold mb-2">Congratulations! 🎉</h2>
                        <p className="font-bold">You have made your Damoa account!</p>
                        <div className="py-8">
                            <img className="w-16 mx-auto" src={blueTickMark} alt="Success" />
                        </div>
                        <button
                            onClick={goToProfile}
                            className="px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                        >
                            Go to my profile
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Register;
