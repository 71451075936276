import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="text-black p-8 mt-8">
      <div className="container mx-auto text-center">
        <p className="font-medium text-sm">Damoa Group Limited</p>
      </div>
    </footer>
  );
};

export default Footer;
