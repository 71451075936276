import React from 'react';
import config from '../../config/config';
import { Link } from 'react-router-dom';

interface PrimaryData {
  title: string;
  description: string;
  imageUrl: string;
  slug: string;
}

interface PrimaryGridProps {
  list: PrimaryData[];
  defaultImg?: string;
}

const PrimaryGrid: React.FC<PrimaryGridProps> = ({ list = [], defaultImg }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
      {list.length > 0 ? (
        list.map((item) => (
          <Link key={item.slug} to={'/jobs/' + item.slug}>
            <div className="bg-white overflow-hidden">
              <img
                src={`${config.imageBaseUrl}/${item.imageUrl}` || defaultImg}
                alt={item.title}
                className="w-full h-72 object-cover rounded-lg"
              />
              <div className="mt-3">
                <h3 className="font-extrabold text-lg truncate whitespace-nowrap">{item.title}</h3>
                <p className="text-light font-extrabold text-md truncate whitespace-nowrap">{item.description}</p>
              </div>
            </div>
          </Link>
        ))
      ) : (
        <p>No jobs available.</p>
      )}
    </div>
  );
};


export default PrimaryGrid;
