// src/components/common/user/EditBusinessProfileModal.tsx
import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { updateBusinessProfile, uploadUserImage } from '../../../features/auth/authSlice';
import { PencilIcon } from '@heroicons/react/24/outline';
import config from '../../../config/config';
import userImg from './../../../assets/images/company.png';

interface BusinessProfile {
    username: string;
    email: string;
    contactNumber: string;
    password: string;
    legalBusinessName: string;
    nzbn: string;
    businessLocation: string;
    businessFields: string;
    bankAccountNumber: string;
}

interface EditBusinessProfileModalProps {
    user: {
        image?: string;
        username: string;
        email: string;
        contactNumber: string;
        businessDetails?: {
            legalBusinessName: string;
            nzbn: string;
            businessLocation: string;
            businessFields: string;
            bankAccountNumber: string;
        };
    };
    onClose: () => void;
}

const EditBusinessProfileModal: React.FC<EditBusinessProfileModalProps> = ({ user, onClose }) => {
    const dispatch = useDispatch<AppDispatch>();

    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        username: user.username,
        email: user.email,
        contactNumber: user.contactNumber,
        password: '',
        legalBusinessName: user.businessDetails?.legalBusinessName || '',
        nzbn: user.businessDetails?.nzbn || '',
        businessLocation: user.businessDetails?.businessLocation || '',
        businessFields: user.businessDetails?.businessFields || '',
        bankAccountNumber: user.businessDetails?.bankAccountNumber || '',
    });
    const [error, setError] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        const filteredData = Object.fromEntries(
            Object.entries(formData).filter(([_, value]) => value.trim() !== "")
        ) as Partial<BusinessProfile>;

        try {
            await dispatch(updateBusinessProfile(filteredData)).unwrap();
            onClose();
        } catch (err) {
            setError(typeof err === 'string' ? err : JSON.stringify(err));
        }
    };

    const imageUrl = user.image ? config.imageBaseUrl + '/' + user.image : userImg;

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const formData = new FormData();
            formData.append('image', event.target.files[0]);
            dispatch(uploadUserImage(formData));
            setIsEditing(false);
        }
    };

    return (
        <Dialog open={true} onClose={onClose} className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
            <div className="relative bg-white rounded-2xl shadow-lg p-6 max-w-lg w-full max-h-[560px] overflow-y-auto mx-auto">
                <h2 className="text-xl font-extrabold mb-4">Edit Business Profile</h2>
                {error && <p className="text-red-600 mb-4">{error}</p>}
                <div className="relative block mb-10 w-52 mx-auto">
                    <img className="rounded-2xl border mx-auto w-52 object-cover" src={imageUrl} alt="User" />
                    {user && (
                        <button
                            className="absolute top-0 right-0 bg-white rounded-full p-2 shadow-md"
                            onClick={() => setIsEditing(!isEditing)}
                        >
                            <PencilIcon className="w-5 h-5 text-gray-600 " />
                        </button>
                    )}
                    {isEditing && (
                        <input
                            type="file"
                            onChange={handleImageChange}
                            className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                        />
                    )}
                </div>
                <div className="space-y-4 overflow-y-auto">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-md mb-2 font-medium">Username</label>
                            <input
                                type="text"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-md mb-2 font-medium">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-md mb-2 font-medium">Contact Number</label>
                            <input
                                type="text"
                                name="contactNumber"
                                value={formData.contactNumber}
                                onChange={handleChange}
                                className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-md mb-2 font-medium">Password</label>
                            <input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-md mb-2 font-medium">Business Name</label>
                            <input
                                type="text"
                                name="legalBusinessName"
                                value={formData.legalBusinessName}
                                onChange={handleChange}
                                className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-md mb-2 font-medium">NZBN</label>
                            <input
                                type="text"
                                name="nzbn"
                                value={formData.nzbn}
                                onChange={handleChange}
                                className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-md mb-2 font-medium">Location</label>
                            <input
                                type="text"
                                name="businessLocation"
                                value={formData.businessLocation}
                                onChange={handleChange}
                                className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-md mb-2 font-medium">Business Fields</label>
                            <input
                                type="text"
                                name="businessFields"
                                value={formData.businessFields}
                                onChange={handleChange}
                                className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-md mb-2 font-medium">Bank Account Number</label>
                            <input
                                type="text"
                                name="bankAccountNumber"
                                value={formData.bankAccountNumber}
                                onChange={handleChange}
                                className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                        </div>
                        <div className="flex justify-end space-x-4">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 font-bold text-gray-600 rounded-md bg-gray-200"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 font-bold text-white rounded-md bg-primary hover:bg-primary-dark"
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    );
};

export default EditBusinessProfileModal;
