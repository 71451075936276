import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { loginUser } from '../../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import DefaultTitle from '../../components/common/titles/DefaultTitle';

const Login: React.FC = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const dispatch = useAppDispatch();
  const { status, error, user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(loginUser(formData))
      .unwrap()
      .then((response) => {
        const redirectTo = response.user.userType === 'normal' ? '/profile' : '/business-profile';
        navigate(redirectTo);
      })
      .catch((err) => {
        console.error('Login failed:', err);
      });
  };

  return (
    <div className="font-sans">
      <DefaultTitle
        title="Damoa account"
        subTitle="Login into your account and work with a variety of professionals."
      />

      <div className="container mx-auto px-8 lg:px-18 py-6">
        <div className="grid grid-cols-2">
          <div>
            {error && <p className="text-red-500 mb-6">{error}</p>}
            <form onSubmit={handleSubmit} className="w-full max-w-md">
              <div className="mb-4">
                <label htmlFor="email" className="block text-md mb-2 font-medium">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="block text-md mb-2 font-medium">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                  disabled={status === 'loading'}
                >
                  {status === 'loading' ? 'Logging in...' : 'Log In'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
