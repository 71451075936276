import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { postBid } from '../../features/bids/bidsSlice';
import { useNavigate } from 'react-router-dom';

const Bid: React.FC<{ projectId: number; clientBudget: number; projectTitle: string }> = ({ projectId, clientBudget, projectTitle }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState<number | undefined>(undefined);
    const [quantity, setQuantity] = useState<number | undefined>(undefined);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { postStatus, postError } = useSelector((state: RootState) => state.bids);
    const { token, user } = useSelector((state: RootState) => state.auth);

    const handleBidSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (price && quantity && description) {
            const bidData = { description, price, quantity, projectId };
            const resultAction = await dispatch(postBid(bidData));

            if (postBid.fulfilled.match(resultAction)) {
                setIsOpen(false);
                setSuccessModalOpen(true);
            } else {
                setErrorModalOpen(true);
            }
        }
    };

    const handleBidClick = () => {
        if (!token) {
            navigate('/sign-in');
        } else if (user?.userType === 'business') {
            setIsOpen(true);
        }
    };

    const totalAmount = price && quantity ? price * quantity * 1.15 : 0;
    const isFormComplete = !!description && !!price && !!quantity;

    return (
        <div>
            {
                (token && user?.userType === 'business') && (
                    <button
                        onClick={handleBidClick}
                        className="px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                    >
                        Bid project
                    </button>
                )
            }


            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
                <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                    <div className="text-left">
                        <h2 className="text-xl font-extrabold mb-2">{projectTitle}</h2>
                        <p className="font-semibold text-gray-600">Client Budget: ${clientBudget}</p>
                        <form className="mt-6 space-y-6" onSubmit={handleBidSubmit}>
                            <div>
                                <label className="block text-sm font-semibold text-labelGrey">DESCRIPTION</label>
                                <textarea
                                    className="w-full shadow-md rounded-lg focus:outline-none focus:ring-2 focus:ring-primary p-2 mt-2"
                                    placeholder="Enter description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    disabled={postStatus === 'loading'}
                                ></textarea>
                            </div>

                            <div className="flex space-x-4">
                                <div className="w-40">
                                    <label className="block text-sm font-semibold text-labelGrey">PRICE</label>
                                    <div className="relative mt-2 shadow-md rounded-lg py-2">
                                        <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">$</span>
                                        <input
                                            type="number"
                                            className="pl-8 pr-10 w-full focus:outline-none focus:ring-0 focus:ring-primary"
                                            placeholder="0.00"
                                            value={price}
                                            onChange={(e) => setPrice(Number(e.target.value))}
                                            disabled={postStatus === 'loading'}
                                        />
                                        <span className="absolute inset-y-0 right-3 flex items-center text-gray-500">NZD</span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-semibold text-labelGrey">QUANTITY</label>
                                <input
                                    type="number"
                                    className="border-gray-300 shadow-md rounded-lg focus:outline-none focus:ring-2 focus:ring-primary p-2 mt-2"
                                    placeholder="0"
                                    value={quantity}
                                    onChange={(e) => setQuantity(Number(e.target.value))}
                                    disabled={postStatus === 'loading'}
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-semibold text-labelGrey">TOTAL AMOUNT - 15% GST</label>
                                <input
                                    type="text"
                                    className="w-full border-gray-300 shadow-md rounded-lg focus:outline-none focus:ring-2 focus:ring-primary p-2 mt-2"
                                    placeholder="$0.00"
                                    value={`$${totalAmount.toFixed(2)}`}
                                    readOnly
                                />
                            </div>

                            <div className="flex justify-between pt-8 space-x-4">
                                <button
                                    type="submit"
                                    className={`w-full px-8 py-3 text-white font-bold rounded-lg ${postStatus === 'loading' || !isFormComplete ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary hover:bg-primary-dark'}`}
                                    disabled={!isFormComplete || postStatus === 'loading'}
                                >
                                    {postStatus === 'loading' ? 'Sending...' : 'Send Invoice'}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                    className="w-full px-8 py-3 bg-primary text-white font-bold rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                    disabled={postStatus === 'loading'}
                                >
                                    Close
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>

            {/* Success Dialog */}
            <Dialog open={successModalOpen} onClose={() => setSuccessModalOpen(false)} className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
                <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                    <div className="text-center">
                        <h2 className="text-xl font-extrabold mb-2">Congratulations! 🎉</h2>
                        <p className="font-bold">Your bid has been placed successfully!</p>
                        <button
                            onClick={() => setSuccessModalOpen(false)}
                            type="button"
                            className="mt-4 px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Dialog>

            {/* Error Dialog */}
            <Dialog open={errorModalOpen} onClose={() => setErrorModalOpen(false)} className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
                <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                    <div className="text-center">
                        <h2 className="text-xl font-extrabold mb-2">Error</h2>
                        <p className="font-bold text-red-600">
                            {typeof postError === 'object' && postError !== null
                                ? postError.message
                                : postError || 'An error occurred. Please try again.'}
                        </p>
                        <button
                            onClick={() => setErrorModalOpen(false)}
                            type="button"
                            className="mt-4 px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default Bid;
