import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeroBanner from '../../components/common/headsections/HeroBanner';
import Filter from '../../components/filters/Filter';
import CompanyGrid from '../../components/CompanyGrid';
import { RootState, AppDispatch } from '../../store';
import { fetchCompanies, setCompanyFilters } from '../../features/companies/companySlice';
import defaultImg from '../../assets/images/company.png';

const cities = [
    { value: '', name: 'All Locations' },
    ...[
        'Auckland', 'Wellington', 'Christchurch', 'Hamilton', 'Tauranga', 'Napier-Hastings',
        'Dunedin', 'Palmerston North', 'Nelson', 'Rotorua', 'New Plymouth', 'Whangarei',
        'Invercargill', 'Whanganui', 'Gisborne', 'Upper Hutt', 'Masterton', 'Timaru',
        'Blenheim', 'Taupo', 'Pukekohe', 'Cambridge', 'Queenstown', 'Levin'
    ].map(city => ({ value: city, name: city }))
];

const categories = [
    { value: '', name: 'All Categories' },
    ...[
        'Painting Jobs',
        'Interior Work',
        'Exterior Work',
        'Roofing',
        'Landscaping',
        'Plumbing'
    ].map(category => ({ value: category, name: category }))
];



const Companies: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { companies, totalPages, currentPage, filters, status, error } = useSelector(
        (state: RootState) => state.companies
    );

    const handleFilterChange = (filterType: string, value: any) => {
        const updatedFilters = {
            ...filters,
            [filterType]: value,
        };
        dispatch(setCompanyFilters(updatedFilters));
        dispatch(fetchCompanies(1));
    };

    const handlePageChange = (page: number) => {
        dispatch(fetchCompanies(page));
    };

    useEffect(() => {
        dispatch(fetchCompanies(currentPage));
    }, [dispatch, currentPage]);

    // Transform the companies data to match CompanyGrid requirements
    const transformedCompanies = companies.map(company => ({
        username: company.username,
        name: company.businessDetails.legalBusinessName,
        rating: company.rating || 0, // or any other default rating if not provided
        imageUrl: company.image,
    }));

    return (
        <div className="font-sans">
            <HeroBanner />
            <div className="container mx-auto px-4 lg:px-18 py-6">
                <section id="filters">
                    <h4 className="font-bold text-sm">FILTER</h4>
                    <div className="flex space-x-4">
                        <Filter
                            label="Location"
                            options={cities}
                            selectedOption={cities.find(city => city.value === filters.location) || cities[0]}
                            onChange={(option) => handleFilterChange('location', option.value)}
                        />
                        <Filter
                            label="Category"
                            options={categories}
                            selectedOption={categories.find(category => category.value === filters.category) || categories[0]}
                            onChange={(option) => handleFilterChange('category', option.value)}
                        />
                    </div>
                </section>

                <section id="listings" className="mt-10">
                    <h4 className="font-black text-sm mb-6">REGISTERED COMPANIES - {companies.length} Listings</h4>
                    {status === 'loading' ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p className="text-red-600">{error}</p>
                    ) : (
                        <CompanyGrid list={transformedCompanies} />
                    )}
                    <div className="mt-20"></div>
                    <div className="text-secondaryLight text-center text-sm font-extrabold tracking-[1em]">
                        {[...Array(totalPages)].map((_, index) => (
                            <span
                                key={index}
                                onClick={() => handlePageChange(index + 1)}
                                className={`cursor-pointer ${index + 1 === currentPage ? 'text-primary' : ''}`}
                            >
                                {index + 1}
                            </span>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Companies;
