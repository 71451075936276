// Profile.tsx
import React, { useState, useEffect } from 'react';
import userImg from './../../assets/images/user/user.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { uploadUserImage } from '../../features/auth/authSlice';
import ReviewList from '../../components/reviews/ReviewList';
import config from '../../config/config';
import { PencilIcon } from '@heroicons/react/24/solid';
import ProjectList from '../../components/projects/ProjectList';
import EditProfileModal from './../../components/common/user/EditProfileModal';

const Profile: React.FC = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const user = useSelector((state: RootState) => state.auth.user);
    const [isEditing, setIsEditing] = useState(false);
    const [isProfileEditing, setIsProfileEditing] = useState(false);


    useEffect(() => {
        if (user?.userType !== 'normal') {
            navigate('/business-profile');
        }
    }, [user, navigate]);

    const imageUrl = user?.image ? config.imageBaseUrl + '/' + user.image : userImg;

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const formData = new FormData();
            formData.append('image', event.target.files[0]);
            dispatch(uploadUserImage(formData));
            setIsEditing(false);
        }
    };

    return (
        <div className="font-sans">
            <div className="container mx-auto px-8 lg:px-18 py-10">
                <div className="grid grid-cols-8 gap-8">
                    <div className="relative block">
                        <img className="rounded-full border h-36 w-full object-cover" src={imageUrl} alt="User" />
                        {user && (
                            <button
                                className="absolute top-0 right-0 bg-white rounded-full p-2 shadow-md"
                                onClick={() => setIsEditing(!isEditing)}
                            >
                                <PencilIcon className="w-5 h-5 text-gray-600 " />
                            </button>
                        )}
                        {isEditing && (
                            <input
                                type="file"
                                onChange={handleImageChange}
                                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                            />
                        )}
                    </div>

                    <div className="col-span-7">
                        <div className="shadow-md w-full p-8 rounded-2xl relative">
                            <h4 className="font-semibold mb-3">Username: <span className="font-normal">{user?.username}</span></h4>
                            <h4 className="font-semibold mb-3">Email: <span className="font-normal">{user?.email}</span></h4>
                            <h4 className="font-semibold mb-3">Contact: <span className="font-normal">{user?.contactNumber}</span></h4>
                            <h4 className="font-semibold mb-3">Rating: {user?.rating ? (<span className="font-normal">{Number(user.rating).toFixed(1) + ' / 5.0'}</span>) : (<span className="font-normal">5.0</span>)}</h4>
                            <button
                                onClick={() => setIsProfileEditing(true)}
                                className="font-bold text-xs text-secondaryLight absolute right-8 top-8"
                            >
                                EDIT PROFILE
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-16"></div>
                <section id="current-projects">
                    <h3 className="font-bold text-xl text-center mb-6">Current Projects</h3>
                    {user && (<ProjectList username={user.username} />)}
                </section>

                <div className="mt-16"></div>
                <section id="current-projects">
                    <h3 className="font-bold text-xl text-center mb-6">User Reviews</h3>
                    <ReviewList userId={user?.id} />
                </section>

                {(isProfileEditing && user) && (
                    <EditProfileModal
                        user={{ username: user.username, email: user.email, contactNumber: user.contactNumber }}
                        onClose={() => setIsProfileEditing(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default Profile;
