import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Layout from "./components/Layout";
import AvailableJobs from "./pages/jobs/AvailableJobs";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import Profile from "./pages/user/Profile";
import BusinessRegister from "./pages/auth/business/BusinessRegister";
import BusinessProfile from "./pages/user/BusinessProfile";
import PostJob from "./pages/jobs/PostJob";
import JobDetail from "./pages/jobs/Job/JobDetail";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute"; // Import PublicRoute
import Companies from "./pages/companies/Companies";
import Company from "./pages/companies/company";

const App: React.FC = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Dashboard />} />

          <Route
            path="/sign-in"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/sign-up"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route
            path="/business-register"
            element={
              <PublicRoute>
                <BusinessRegister />
              </PublicRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/business-profile"
            element={
              <PrivateRoute>
                <BusinessProfile />
              </PrivateRoute>
            }
          />
          <Route path="/jobs" element={<AvailableJobs />} />
          <Route
            path="/post-job"
            element={
              <PrivateRoute>
                <PostJob />
              </PrivateRoute>
            }
          />
          <Route path="/jobs/:slug" element={<JobDetail />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/companies/:username" element={<Company />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
