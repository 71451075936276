import React from 'react';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

interface Option {
  value: any;
  name: string;
}

interface FilterProps {
  label: string;
  options: Option[];
  selectedOption: Option;
  onChange: (option: Option) => void;
}

const Filter: React.FC<FilterProps> = ({ label, options, selectedOption, onChange }) => {
  return (
    <Listbox value={selectedOption} onChange={onChange}>
      <div className="relative mt-2 min-w-48">
        <ListboxButton className="relative w-full cursor-default rounded-xl bg-white py-3 pl-4 pr-10 text-left text-gray-900 shadow-md focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6">
          <span className="flex items-center">
            <span className="font-bold">{label}</span>
            <span className="ml-3 block truncate font-bold text-light text-sm">{selectedOption.name}</span>
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <ChevronDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {options.map((option) => (
            <ListboxOption
              key={option.value}
              value={option}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-primary data-[focus]:text-white"
            >
              <div className="flex items-center">
                <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                  {option.name}
                </span>
              </div>

              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
};

export default Filter;
