import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/auth/authSlice';
import userReducer from './features/user/userSlice';
import jobsReducer from './features/jobs/jobSlice';
import jobDetailReducer from './features/jobs/jobDetailSlice';
import companiesReducer from './features/companies/companySlice';
import companyDetailReducer from './features/companies/companyDetailSlice';
import bidsReducer from './features/bids/bidsSlice';
import hireReducer from './features/hiring/hireSlice';
import reviewsReducer from './features/reviews/reviewsSlice';
import projectsReducer from './features/projects/projectSlice';
import businessImagesReducer from './features/businessImages/businessImagesSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        jobs: jobsReducer,
        jobDetail: jobDetailReducer,
        companies: companiesReducer,
        companyDetail: companyDetailReducer,
        bids: bidsReducer,
        hire: hireReducer,
        reviews: reviewsReducer,
        projects: projectsReducer,
        businessImages: businessImagesReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
