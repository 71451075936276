import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import config from '../../config/config';

interface BusinessDetails {
  id: number;
  userId: number;
  legalBusinessName: string;
  nzbn: string;
  businessLocation: string;
  businessFields: string;
  bankAccountNumber: string;
  images: string[];
}
// User type for logged-in user data
interface User {
  id: number;
  username: string;
  image?: string;
  email: string;
  contactNumber: string;
  userType: string;
  rating?: number;
  legalBusinessName?: string;
  nzbn?: string;
  businessLocation?: string;
  businessFields?: string;
  bankAccountNumber?: string;
  businessDetails?: BusinessDetails;
}

interface UserProfile {
  username: string;
  email: string;
  contactNumber: string;
  password: string;
}

// Separate type for registration data that excludes 'id'
interface RegisterBusinessUserData {
  username: string;
  email: string;
  image?: string;
  contactNumber: string;
  password: string;
  userType: string;
  legalBusinessName: string;
  nzbn: string;
  businessLocation: string;
  businessFields: string;
  bankAccountNumber: string;
}

// AuthState type for slice
interface AuthState {
  token: string | null;
  user: User | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Define the response structure for login and register
interface LoginResponse {
  message: string;
  token: string;
  user: User;
}

interface RegisterResponse {
  message: string;
  user: User;
}

// Thunk for login action
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (loginData: { email: string; password: string }, thunkAPI) => {
    try {
      const response = await axios.post<LoginResponse>(`${config.apiBaseUrl}/auth/login`, loginData);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        return thunkAPI.rejectWithValue(axiosError.response?.data?.message || 'An error occurred');
      }
      return thunkAPI.rejectWithValue('An unknown error occurred');
    }
  }
);

// Thunk for registering a business user
export const registerBusinessUser = createAsyncThunk(
  'auth/registerBusinessUser',
  async (userData: RegisterBusinessUserData, thunkAPI) => {
    try {
      const response = await axios.post<RegisterResponse>(`${config.apiBaseUrl}/auth/register`, userData);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ message: string }>;
        return thunkAPI.rejectWithValue(axiosError.response?.data?.message || 'An error occurred');
      }
      return thunkAPI.rejectWithValue('An unknown error occurred');
    }
  }
);

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (profileData: Partial<UserProfile>, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${config.apiBaseUrl}/user/profile`, profileData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      localStorage.setItem('user', JSON.stringify(response.data.user));
      return response.data.user;
    } catch (error) {
      const axiosError = error as AxiosError;
      return rejectWithValue(axiosError.response?.data || 'An error occurred');
    }
  }
);

export const updateBusinessProfile = createAsyncThunk(
  'auth/updateBusinessProfile',
  async (profileData: Partial<User & BusinessDetails>, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${config.apiBaseUrl}/business-user/profile`, profileData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      localStorage.setItem('user', JSON.stringify(response.data.user));
      return response.data.user;
    } catch (error) {
      const axiosError = error as AxiosError;
      return rejectWithValue(axiosError.response?.data || 'An error occurred');
    }
  }
);



export const uploadUserImage = createAsyncThunk(
  'user/uploadUserImage',
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${config.apiBaseUrl}/user/image`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;
      if (user) {
        localStorage.setItem('user', JSON.stringify({ ...user, image: response.data.user.image }));
      }
      return response.data.user;
    } catch (error) {
      const axiosError = error as AxiosError;
      return rejectWithValue(axiosError.response?.data || 'An error occurred');
    }
  }
);

// Initialize state with token and user from local storage
const initialState: AuthState = {
  token: localStorage.getItem('token'),
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null,
  status: 'idle',
  error: null,
};

// Create the auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    },
    updateUserImages: (state, action: PayloadAction<string[]>) => {
      if (state.user && state.user.businessDetails) {
        state.user.businessDetails.images = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.token = action.payload.token;
        state.user = action.payload.user;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(registerBusinessUser.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(registerBusinessUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
      })
      .addCase(registerBusinessUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(uploadUserImage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(uploadUserImage.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (state.user) {
          state.user.image = action.payload.image;
        }
      })
      .addCase(uploadUserImage.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      })
      .addCase(updateBusinessProfile.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateBusinessProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

  },
});

export const { logout, updateUserImages } = authSlice.actions;
export default authSlice.reducer;
