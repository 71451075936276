import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../../config/config';
import { AxiosError } from 'axios';
import { updateUserImages } from '../auth/authSlice';

interface ErrorResponse {
    message?: string;
}

interface ImageState {
    images: string[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: ImageState = {
    images: [],
    status: 'idle',
    error: null,
};

// Upload Image Action
export const uploadBusinessImage = createAsyncThunk(
    'businessImages/upload',
    async (file: File, { rejectWithValue, dispatch }) => {
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post(`${config.apiBaseUrl}/business-user/upload-image`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Get the updated list of images from the response
            const updatedImages = response.data.images;

            // Update the user images in the auth state
            dispatch(updateUserImages(updatedImages));

            // Update localStorage with the new list of images
            const authUser = JSON.parse(localStorage.getItem('user') || '{}');
            authUser.businessDetails.images = updatedImages;
            localStorage.setItem('user', JSON.stringify(authUser));

            return updatedImages;
        } catch (error) {
            const axiosError = error as AxiosError<ErrorResponse>;
            return rejectWithValue(axiosError.response?.data?.message || 'Failed to upload image');
        }
    }
);

export const deleteBusinessImage = createAsyncThunk(
    'businessImages/delete',
    async (imageUrl: string, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await axios.delete(`${config.apiBaseUrl}/business-user/delete-image`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                data: { imageUrl },
            });

            // Update the user images in the auth state
            const updatedImages = response.data.images;
            dispatch(updateUserImages(updatedImages));

            // Update localStorage with the new list of images
            const authUser = JSON.parse(localStorage.getItem('user') || '{}');
            authUser.businessDetails.images = updatedImages;
            localStorage.setItem('user', JSON.stringify(authUser));

            return updatedImages;
        } catch (error) {
            const axiosError = error as AxiosError<ErrorResponse>;
            return rejectWithValue(axiosError.response?.data?.message || 'Failed to delete image');
        }
    }
);

// Slice
const businessImagesSlice = createSlice({
    name: 'businessImages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(uploadBusinessImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(uploadBusinessImage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.images = action.payload.images;
            })
            .addCase(uploadBusinessImage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(deleteBusinessImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteBusinessImage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.images = action.payload.images;
            })
            .addCase(deleteBusinessImage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export default businessImagesSlice.reducer;
