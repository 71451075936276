import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { hireBid, clearSuccessMessage } from '../../features/hiring/hireSlice';
import { fetchBidsByProject } from '../../features/bids/bidsSlice';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

interface Bidder {
    id: number;
    username: string;
    email: string;
    contactNumber: string;
    businessDetails: {
        legalBusinessName: string;
        nzbn: string;
    };
}

interface Bid {
    id: number;
    description: string;
    price: number;
    currency: string;
    quantity: number;
    totalAmount: number;
    gst: number;
    projectId: number;
    userId: number;
    createdAt: string;
    updatedAt: string;
    bidder: Bidder;
}

interface HireNowProps {
    isOpen: boolean;
    onClose: () => void;
    bid: Bid;
}

const HireNow: React.FC<HireNowProps> = ({ isOpen, onClose, bid }) => {
    const dispatch = useDispatch<AppDispatch>();
    const stripe = useStripe();
    const elements = useElements();
    const { hireStatus, successMessage, hireError } = useSelector((state: RootState) => state.hire);
    const [isPaymentForm, setIsPaymentForm] = useState(false);

    useEffect(() => {
        if (successMessage) {
            setTimeout(() => dispatch(clearSuccessMessage()), 10000);
        }
    }, [successMessage, dispatch]);

    useEffect(() => {
        if (hireError) {
            setIsPaymentForm(true);
        }
    }, [hireError]);

    const handleCloseDialog = () => {
        onClose();
        dispatch(fetchBidsByProject(bid.projectId)); // Refresh bids when the dialog closes
    };

    const handleSubmitPayment = async () => {
        if (!stripe || !elements) return;

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) return;

        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error || !paymentMethod) {
            console.error('Payment failed:', error || 'No payment method found');
            return;
        }

        dispatch(
            hireBid({
                projectId: bid.projectId,
                bidId: bid.id,
                paymentMethodId: paymentMethod.id,
            })
        );
    };

    return (
        <Dialog open={isOpen} onClose={handleCloseDialog} className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
            <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                <XMarkIcon onClick={handleCloseDialog} className="absolute w-4 cursor-pointer right-8" />

                {successMessage ? (
                    <div className="text-center">
                        <h2 className="text-xl font-extrabold mb-2">{successMessage}</h2>
                        <button
                            onClick={handleCloseDialog}
                            className="mt-4 px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                        >
                            Close
                        </button>
                    </div>
                ) : (
                    <div className="text-left">
                        <h2 className="text-xl font-extrabold mb-2">Hire {bid.bidder.businessDetails.legalBusinessName}</h2>
                        <p className="font-semibold">Project amount: ${bid.totalAmount}</p>

                        <div className="mt-4">
                            <p className="font-semibold">What is Damoa Pay?</p>
                            <p className="font-semibold text-extraGrey">Damoa Pay is a safety method payment which protects users from any fraud or conflicts between the user and the company who is working on the project. </p>
                        </div>

                        {isPaymentForm ? (
                            <div className="space-y-4 pt-6">
                                <CardElement className="p-4 border rounded-lg" />
                                {hireError && (
                                    <p className="text-red-600">
                                        {typeof hireError === 'object' && 'error' in hireError
                                            ? (typeof hireError.error === 'string' ? hireError.error : JSON.stringify(hireError.error))
                                            : typeof hireError === 'string'
                                                ? hireError
                                                : 'An unknown error occurred.'}
                                    </p>
                                )}

                                <div className="flex justify-between space-x-4">
                                    <button
                                        onClick={() => setIsPaymentForm(false)}
                                        className="w-full px-8 py-3 text-gray-600 font-bold rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                    >
                                        Back
                                    </button>
                                    <button
                                        onClick={handleSubmitPayment}
                                        disabled={hireStatus === 'loading'}
                                        className="w-full px-8 py-3 text-white font-bold rounded-lg bg-primary hover:bg-primary-dark focus:outline-none"
                                    >
                                        {hireStatus === 'loading' ? 'Paying...' : 'Pay Now'}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="flex justify-between pt-8 space-x-4">
                                <button
                                    className="w-full px-8 py-3 text-white font-bold rounded-lg bg-primary hover:bg-primary-dark focus:outline-none"
                                >
                                    View Invoice
                                </button>
                                <button
                                    onClick={() => setIsPaymentForm(true)}
                                    className="w-full px-8 py-3 bg-primary text-white font-bold rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                >
                                    Pay Now
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default HireNow;
