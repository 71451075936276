import React from 'react';
import defaultImg from '../assets/images/company.png';
import { Link } from 'react-router-dom';
import config from '../config/config';
interface CompanyData {
  username: string;
  name: string;
  rating?: number;
  imageUrl?: string;
}

interface CompanyGridProps {
  list: CompanyData[];
}

const CompanyGrid: React.FC<CompanyGridProps> = ({ list }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
      {list.map((item, index) => (
        <div key={index}>
          <Link to={'/companies/' + item.username}>
            <div key={item.name} className="bg-white overflow-hidden">
              <img
                src={item.imageUrl ? `${config.imageBaseUrl}/${item.imageUrl}` : defaultImg}
                alt={item.name}
                className="w-full h-64 object-cover rounded-lg image-box-shadow"
              />
              <div className="mt-3">
                <h3 className="font-extrabold text-lg">{item.name}</h3>
                {item.rating ? (<p className="text-light font-medium text-md">{Number(item.rating).toFixed(1) + ' / 5.0'}</p>) : (<p className="text-light font-medium text-md">5.0</p>)}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>

  );
};

export default CompanyGrid;
