import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReviewItem from './ReviewItem';
import WriteReview from './WriteReview'; // Import the WriteReview component
import { fetchReviews } from '../../features/reviews/reviewsSlice';
import { RootState, AppDispatch } from '../../store';

interface ReviewListProps {
    userId?: number;
    projectId?: number;
}

const ReviewList: React.FC<ReviewListProps> = ({ userId, projectId }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { reviews, status, error } = useSelector((state: RootState) => state.reviews);
    const [isWriteReviewOpen, setWriteReviewOpen] = useState(false);

    useEffect(() => {
        if (userId || projectId) {
            dispatch(fetchReviews({ userId, projectId }));
        }
    }, [dispatch, userId, projectId]);

    const handleReport = (id?: number) => {
        console.log(id);
        alert('Reported!');
    };

    if (error) return <p className="text-red-600">{error}</p>;

    return (
        <>
            <div className="text-center">{status === 'loading' && 'Loading...'}</div>
            {(reviews && reviews.length > 0) ? (
                <div className="space-y-2">
                    <div className="rounded-2xl shadow-md px-8 py-4">
                        <ul>
                            {reviews.map((review, index) => (
                                <ReviewItem
                                    key={review.id}
                                    title={review.reviewer.username}
                                    rating={review.rating}
                                    comment={review.comment}
                                    isLast={index === reviews.length - 1}
                                    onReport={() => handleReport(review.id)}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            ) : (
                <div className="text-center">No Reviews</div>
            )}

            {projectId && (
                <>
                    <div className="mt-10"></div>
                    <div className="mx-auto text-center">
                        <button
                            type="button"
                            onClick={() => setWriteReviewOpen(true)} // Open modal on click
                            className="px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                        >
                            Write Review
                        </button>
                    </div>
                </>
            )}

            {(projectId && isWriteReviewOpen) && (
                <WriteReview
                    projectId={projectId}
                    onClose={() => setWriteReviewOpen(false)}
                />
            )}
        </>
    );
};

export default ReviewList;
