import React from 'react'; 
import { useAppSelector } from '../redux/hooks';
import { Navigate } from 'react-router-dom';

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { token } = useAppSelector((state) => state.auth);

  return token ? children : <Navigate to="/sign-in" />;
};

export default PrivateRoute;
