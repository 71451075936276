import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import config from '../../config/config';

interface Bidder {
    id: number;
    username: string;
    email: string;
    contactNumber: string;
    rating: number;
    businessDetails: {
        legalBusinessName: string;
        nzbn: string;
    };
}

interface Bid {
    id: number;
    description: string;
    price: number;
    currency: string;
    quantity: number;
    totalAmount: number;
    gst: number;
    projectId: number;
    userId: number;
    createdAt: string;
    updatedAt: string;
    bidder: Bidder;
}

interface BidsState {
    bids: Bid[];
    totalBids: number;
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    fetchError: string | null;
    postStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
    postError: string | { message: string } | null;
}

const initialState: BidsState = {
    bids: [],
    totalBids: 0,
    fetchStatus: 'idle',
    fetchError: null,
    postStatus: 'idle',
    postError: null,
};

export const fetchBidsByProject = createAsyncThunk(
    'bids/fetchBidsByProject',
    async (projectId: number, { rejectWithValue }) => {
        try {
            const response = await axios.get<{ totalBids: number; bids: Bid[] }>(
                `${config.apiBaseUrl}/projects/${projectId}/bids`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            return rejectWithValue(axiosError.response?.data || 'An error occurred');
        }
    }
);

export const postBid = createAsyncThunk(
    'bids/postBid',
    async (
        bidData: { description: string; price: number; quantity: number; projectId: number },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(`${config.apiBaseUrl}/bids`, bidData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError;
            return rejectWithValue(axiosError.response?.data || 'An error occurred');
        }
    }
);

const bidsSlice = createSlice({
    name: 'bids',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch Bids
            .addCase(fetchBidsByProject.pending, (state) => {
                state.fetchStatus = 'loading';
                state.fetchError = null;
            })
            .addCase(fetchBidsByProject.fulfilled, (state, action) => {
                state.fetchStatus = 'succeeded';
                state.bids = action.payload.bids;
                state.totalBids = action.payload.totalBids;
            })
            .addCase(fetchBidsByProject.rejected, (state, action) => {
                state.fetchStatus = 'failed';
                state.fetchError = action.payload as string;
            })
            // Post Bid
            .addCase(postBid.pending, (state) => {
                state.postStatus = 'loading';
                state.postError = null;
            })
            .addCase(postBid.fulfilled, (state, action) => {
                state.postStatus = 'succeeded';
                state.bids.unshift(action.payload.bid);
            })
            .addCase(postBid.rejected, (state, action) => {
                state.postStatus = 'failed';
                state.postError = action.payload as string;
            });
    },
});

export default bidsSlice.reducer;
