import React, { useState } from 'react';
import Select, { MultiValue, ActionMeta } from 'react-select';
import { Dialog } from '@headlessui/react';
import DefaultTitle from '../../../components/common/titles/DefaultTitle';
import blueTickMark from './../../../assets/images/icons/blue-tickmark.png';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { registerBusinessUser } from '../../../features/auth/authSlice';

const newZealandCities = [
    'Auckland', 'Wellington', 'Christchurch', 'Hamilton', 'Tauranga', 'Napier-Hastings',
    'Dunedin', 'Palmerston North', 'Nelson', 'Rotorua', 'New Plymouth', 'Whangarei',
    'Invercargill', 'Whanganui', 'Gisborne', 'Upper Hutt', 'Masterton', 'Timaru',
    'Blenheim', 'Taupo', 'Pukekohe', 'Cambridge', 'Queenstown', 'Levin'
];

const businessFieldsOptions = [
    { value: 'Painting Jobs', label: 'Painting Jobs' },
    { value: 'Interior Work', label: 'Interior Work' },
    { value: 'Exterior Work', label: 'Exterior Work' },
    { value: 'Roofing', label: 'Roofing' },
    { value: 'Landscaping', label: 'Landscaping' },
    { value: 'Plumbing', label: 'Plumbing' },
];

const BusinessRegister: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { status, error } = useAppSelector((state) => state.auth);

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        contactNumber: '',
        password: '',
        confirmPassword: '',
        userType: 'business',
        legalBusinessName: '',
        nzbn: '',
        businessLocation: '',
        businessFields: [] as Array<{ value: string; label: string }>,
        bankAccountNumber: '',
        reEnterBankAccountNumber: '',
    });

    const [isOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(1);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSelectChange = (
        selectedOptions: MultiValue<{ value: string; label: string }>
    ) => {
        setFormData({
            ...formData,
            businessFields: selectedOptions as Array<{ value: string; label: string }>,
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (formData.password !== formData.confirmPassword) {
            alert("Passwords do not match.");
            return;
        }

        if (formData.bankAccountNumber !== formData.reEnterBankAccountNumber) {
            alert("Bank account numbers do not match.");
            return;
        }

        const registrationData = {
            ...formData,
            username: formData.legalBusinessName,
            businessFields: formData.businessFields.map(field => field.value).join(', '),
        };

        try {
            await dispatch(registerBusinessUser(registrationData)).unwrap();
            setIsOpen(true);
        } catch (error) {
            console.error('Registration failed:', error);
        }
    };

    const goToProfile = () => {
        setIsOpen(false);
        navigate('/business-profile');
    };


    return (
        <div className="font-sans">
            <DefaultTitle
                title="Register as a business and start working on projects!"
                subTitle="Register your business on Damoa and find new clients."
            />

            <div className="container mx-auto px-8 lg:px-18 py-6">
                <div className="grid grid-cols-2">
                    <div>
                        {error && <p className="text-red-500 mb-6">{error}</p>}
                        <form onSubmit={handleSubmit} className="w-full">
                            {step === 1 ? (
                                <>
                                    <div className="mb-4">
                                        <label htmlFor="legalBusinessName" className="block text-md mb-2 font-medium">Legal business name</label>
                                        <input
                                            type="text"
                                            id="legalBusinessName"
                                            name="legalBusinessName"
                                            value={formData.legalBusinessName}
                                            onChange={handleChange}
                                            className="w-full p-3 mb-4 shadow-md rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="nzbn" className="block text-md mb-2 font-medium">Business NZBN</label>
                                        <input
                                            type="text"
                                            id="nzbn"
                                            name="nzbn"
                                            value={formData.nzbn}
                                            onChange={handleChange}
                                            className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="contactNumber" className="block text-md mb-2 font-medium">Contact number</label>
                                        <input
                                            type="text"
                                            id="contactNumber"
                                            name="contactNumber"
                                            value={formData.contactNumber}
                                            onChange={handleChange}
                                            className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="businessLocation" className="block text-md mb-2 font-medium">Business Location</label>
                                        <select
                                            id="businessLocation"
                                            name="businessLocation"
                                            value={formData.businessLocation}
                                            onChange={handleChange}
                                            className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                        >
                                            <option value="">Select a city</option>
                                            {newZealandCities.map((city) => (
                                                <option key={city} value={city}>{city}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="businessFields" className="block text-md mb-2 font-medium">Business fields</label>
                                        <Select
                                            isMulti
                                            name="businessFields"
                                            options={businessFieldsOptions}
                                            value={formData.businessFields}
                                            onChange={handleSelectChange}
                                            className="shadow-md mb-4 rounded-2xl px-2 py-1 focus:outline-none focus:ring-2 focus:ring-primary"
                                            classNamePrefix="select"
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: 'none', // Removes the border
                                                    boxShadow: 'none', // Removes any focus shadow
                                                    '&:hover': {
                                                        border: 'none', // Ensures no border on hover
                                                    },
                                                }),
                                            }}
                                        />

                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="bankAccountNumber" className="block text-md mb-2 font-medium">Bank account number</label>
                                        <input
                                            type="text"
                                            id="bankAccountNumber"
                                            name="bankAccountNumber"
                                            value={formData.bankAccountNumber}
                                            onChange={handleChange}
                                            className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="reEnterBankAccountNumber" className="block text-md mb-2 font-medium">Re-enter Bank account number</label>
                                        <input
                                            type="text"
                                            id="reEnterBankAccountNumber"
                                            name="reEnterBankAccountNumber"
                                            value={formData.reEnterBankAccountNumber}
                                            onChange={handleChange}
                                            className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                        />
                                    </div>
                                    <button
                                        onClick={() => setStep(2)}
                                        type="button"
                                        className="px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                                    >
                                        Next
                                    </button>
                                </>
                            ) : (
                                <>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="block text-md mb-2 font-medium">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="password" className="block text-md mb-2 font-medium">Password</label>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                        />
                                    </div>
                                    <div className="mb-6">
                                        <label htmlFor="confirmPassword" className="block text-md mb-2 font-medium">Confirm Password</label>
                                        <input
                                            type="password"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        disabled={status === "loading"}
                                        className="px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                                    >
                                        {status === "loading" ? 'Creating..' : 'Create account'}
                                    </button>
                                    <button
                                        onClick={() => setStep(1)}
                                        type="button"
                                        className="ml-10 px-14 py-4 bg-secondaryLight text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                                    >
                                        Go back
                                    </button>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            </div>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
                <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                    <div className="text-center">
                        <h2 className="text-xl font-extrabold mb-2">Congratulations! 🎉</h2>
                        <p className="font-bold">You have created your Business account!</p>
                        <div className="py-8">
                            <img className="w-16 mx-auto" src={blueTickMark} alt="Success" />
                        </div>
                        <button
                            onClick={goToProfile}
                            type="button"
                            className="px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                        >
                            Go to business profile
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default BusinessRegister;
