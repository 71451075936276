// src/components/reviews/WriteReview.tsx
import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { StarIcon as FilledStarIcon } from '@heroicons/react/24/solid';
import { StarIcon as OutlineStarIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { submitReview } from '../../features/reviews/reviewsSlice';

interface WriteReviewProps {
    projectId: number;
    onClose: () => void;
}

const WriteReview: React.FC<WriteReviewProps> = ({ projectId, onClose }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');

    const handleRatingClick = (value: number) => {
        setRating(value);
    };

    const handleSubmit = () => {
        dispatch(submitReview({ projectId, rating, comment })).then(() => {
            onClose();
        });
    };

    return (
        <Dialog open={true} onClose={onClose} className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
            <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                <h2 className="text-xl font-extrabold mb-4">Write a Review</h2>
                <div className="flex items-center mb-4">
                    {[1, 2, 3, 4, 5].map((value) => (
                        <button key={value} onClick={() => handleRatingClick(value)}>
                            {value <= rating ? (
                                <FilledStarIcon className="w-6 text-yellow-400" />
                            ) : (
                                <OutlineStarIcon className="w-6 text-yellow-400" />
                            )}
                        </button>
                    ))}
                </div>
                <textarea
                    className="w-full p-4 border rounded-md mb-4"
                    placeholder="Write your comment here..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                ></textarea>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 font-bold text-gray-600 rounded-md bg-gray-200"
                    >
                        Close
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="px-4 py-2 font-bold text-white rounded-md bg-primary hover:bg-primary-dark"
                    >
                        Post Now
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default WriteReview;
