import React from 'react';
import HeroBanner from '../components/common/headsections/HeroBanner';
import pencil from './../assets/images/icons/pencil.png';
import handshake from './../assets/images/icons/hand-shake.png';
import checkmark from './../assets/images/icons/checkmark.png';

const Dashboard: React.FC = () => {
    return (
        <div className="font-sans">
            <HeroBanner />

            <section id="how-it-works">
                <div className="bg-primary py-4">
                    <div className="container mx-auto px-4 lg:px-8">
                        <h3 className="font-bold text-2xl text-white">How does Damoa work?</h3>
                    </div>
                </div>

                <div className="container mx-auto px-4 lg:px-18 py-20">
                    <div className="relative">
                        <div className="absolute top-3  inset-0 flex items-center justify-between right-32 left-28">
                            <div className="flex-1 h-0.5 bg-primary"></div>
                            <div className="flex-1 h-0.5 bg-primary"></div>
                        </div>
                        <ol className="relative z-10 grid grid-cols-3 font-medium text-primary-500 gap-4">
                            <li className="relative flex flex-col items-center">
                                <img src={pencil} className="w-12 mb-3" />
                                <div className="relative rounded-2xl px-6 py-3 bg-blue-900 text-white text-sm shadow-md sm:order-2">
                                    1. Share your project details
                                </div>
                                <div className="relative max-w-48 sm:order-3 mt-3">
                                    <p className="text-secondary font-medium text-[12px] text-center">Quickly, easily, and for free, 
                                    describe what you need done.</p>
                                </div>
                            </li>

                            <li className="relative flex flex-col items-center">
                                <img src={handshake} className="w-12 mb-3" />
                                <div className="relative rounded-2xl px-6 py-3 bg-blue-900 text-white text-sm shadow-md sm:order-2">
                                    2. Get matched with partners
                                </div>
                                <div className="relative max-w-48 sm:order-3 mt-3">
                                    <p className="text-secondary font-medium text-[12px] text-center">Get connected with 
                                    professionals for your project.</p>
                                </div>
                            </li>

                            <li className="relative flex flex-col items-center">
                                <img src={checkmark} className="w-12 mb-3" />
                                <div className="relative rounded-2xl px-6 py-3 bg-blue-900 text-white text-sm shadow-md sm:order-2">
                                    3. Start your project!
                                </div>
                                <div className="relative max-w-48 sm:order-3 mt-3">
                                    <p className="text-secondary font-medium text-[12px] text-center">Check their profiles 
                                    and begin your project.</p>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>


            </section>
        </div>
    );
};

export default Dashboard;
