import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import config from '../../config/config';

interface User {
    id: number;
    username: string;
    email: string;
    userType: string;
}

interface Project {
    hiredBidderId: any;
    id: number;
    title: string;
    slug: string;
    user: { id: number; username: string; userType: string };
    bids: any[];
}

interface ProjectState {
    user: User | null;
    projects: Project[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: ProjectState = {
    user: null,
    projects: [],
    status: 'idle',
    error: null,
};

export const fetchUserProjects = createAsyncThunk<
    { user: User; projects: Project[] }, // Return type
    string, // Argument type
    { rejectValue: string } // Thunk API config
>(
    'projects/fetchUserProjects',
    async (userSlug, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${config.apiBaseUrl}/projects/userProjects?userSlug=${userSlug}`);
            return response.data;
        } catch (err) {
            const error = err as AxiosError<{ message: string }>;
            const errorMessage = error.response?.data?.message || 'Failed to fetch projects';
            return rejectWithValue(errorMessage);
        }
    }
);

const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProjects.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchUserProjects.fulfilled, (state, action: PayloadAction<{ user: User; projects: Project[] }>) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
                state.projects = action.payload.projects;
            })
            .addCase(fetchUserProjects.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'An error occurred';
            });
    },
});

export default projectSlice.reducer;
