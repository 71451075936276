import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobDetail } from '../../../features/jobs/jobDetailSlice';
import { RootState, AppDispatch } from '../../../store';
import LightboxGallery from '../../../components/common/LightboxGallery';
import BidList from '../../../components/jobs/BidList';
import Bid from '../../../components/jobs/Bid';

const JobDetail: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const { jobDetail, status, error } = useSelector((state: RootState) => state.jobDetail);

    useEffect(() => {
        if (slug) dispatch(fetchJobDetail(slug));
    }, [slug, dispatch]);

    if (error) return <p className="text-red-600">{error}</p>;
    if (!jobDetail) return <p>No job found</p>;

    return (
        <div className="font-sans">
            <div className="container mx-auto px-8 lg:px-18 py-10">
                {status === "loading" && 'Loading...'}
                <div className="grid grid-cols-12 gap-8">
                    <div className="col-span-5">
                        <LightboxGallery images={jobDetail.images} />
                    </div>

                    <div className="col-span-7 flex">
                        <div className="w-full flex flex-col justify-between">
                            <div>
                                <h2 className="text-3xl font-bold mb-6">{jobDetail.title}</h2>
                                <div className="pb-6 border-b border-gray-300">
                                    <h4 className="font-bold text-md mb-4">Post description</h4>
                                    <p className="text-sm font-normal">{jobDetail.description}</p>
                                </div>

                                <div className="mb-6 mt-3">
                                    <h4 className="font-bold text-md mb-4">Additional information</h4>
                                    <ul className="space-y-4">
                                        <li className="text-sm"><span className="font-semibold">User:</span> {jobDetail.user.username}</li>
                                        <li className="text-sm"><span className="font-semibold">Location:</span> {jobDetail.location}, New Zealand</li>
                                        <li className="text-sm"><span className="font-semibold">Work type:</span> {jobDetail.category}</li>
                                        <li className="text-sm"><span className="font-semibold">Budget:</span> ${jobDetail.budget}</li>
                                        {jobDetail.specialInstructions && (<li className="text-sm"><span className="font-semibold">Special instructions:</span> {jobDetail.specialInstructions}</li>)}
                                    </ul>
                                </div>

                                {(!jobDetail.hiredBidderId && jobDetail.status === "open") && (<Bid projectId={jobDetail.id} clientBudget={jobDetail.budget} projectTitle={jobDetail.title} />)}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-28"></div>
                <section id="current-projects">
                    <h3 className="font-bold text-xl text-center mb-6">Project Biddings</h3>

                    {jobDetail.hiredBidderId ? (
                        <>
                            <BidList
                                projectOwnerId={jobDetail.user.id}
                                projectId={jobDetail.id}
                                hiredBidderId={jobDetail.hiredBidderId}
                                showOnlyHired={true}
                            />

                        </>
                    ) : (
                        <BidList
                            projectOwnerId={jobDetail.user.id}
                            projectId={jobDetail.id}
                            hiredBidderId={null}
                            showOnlyHired={false}
                        />
                    )}
                </section>
            </div>
        </div>
    );
};

export default JobDetail;
