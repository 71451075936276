import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import config from '../../config/config';

interface Company {
    image: string;
    id: number;
    username: string;
    email: string;
    contactNumber: string;
    userType: string;
    imageUrl: string;
    rating: number;
    businessDetails: {
        legalBusinessName: string;
        nzbn: string;
        businessLocation: string;
        businessFields: string;
        bankAccountNumber: string;
        images?: string[];
    };
}

interface CompanyListResponse {
    totalItems: number;
    totalPages: number;
    currentPage: number;
    companies: Company[];
}

interface CompanyState {
    companies: Company[];
    totalPages: number;
    currentPage: number;
    filters: {
        location: string;
        category: string;
    };
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

// Thunk to fetch companies with filters and pagination
export const fetchCompanies = createAsyncThunk(
    'companies/fetchCompanies',
    async (page: number, { getState, rejectWithValue }) => {
        const state = getState() as { companies: CompanyState };
        const { location, category } = state.companies.filters;

        try {
            const response = await axios.get<CompanyListResponse>(`${config.apiBaseUrl}/companies`, {
                params: {
                    location,
                    category,
                    page,
                    limit: 8,
                },
            });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError<{ error: string }>;
                return rejectWithValue(axiosError.response?.data?.error || 'Failed to fetch companies');
            }
            return rejectWithValue('An unknown error occurred');
        }
    }
);

const initialState: CompanyState = {
    companies: [],
    totalPages: 1,
    currentPage: 1,
    filters: {
        location: '',
        category: '',
    },
    status: 'idle',
    error: null,
};

const companySlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        setCompanyFilters(state, action) {
            state.filters = { ...state.filters, ...action.payload };
        },
        resetCompanyFilters(state) {
            state.filters = initialState.filters;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanies.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchCompanies.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.companies = action.payload.companies;
                state.totalPages = action.payload.totalPages;
                state.currentPage = action.payload.currentPage;
            })
            .addCase(fetchCompanies.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { setCompanyFilters, resetCompanyFilters } = companySlice.actions;
export default companySlice.reducer;
