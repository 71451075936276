import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import DefaultTitle from '../../components/common/titles/DefaultTitle';
import blueTickMark from './../../assets/images/icons/blue-tickmark.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postJob } from '../../features/jobs/jobSlice';
import { AppDispatch, RootState } from '../../store';
import ImageUpload from '../../components/jobs/ImageUpload';


const cities = [
    'Auckland', 'Wellington', 'Christchurch', 'Hamilton', 'Tauranga', 'Napier-Hastings',
    'Dunedin', 'Palmerston North', 'Nelson', 'Rotorua', 'New Plymouth', 'Whangarei',
    'Invercargill', 'Whanganui', 'Gisborne', 'Upper Hutt', 'Masterton', 'Timaru',
    'Blenheim', 'Taupo', 'Pukekohe', 'Cambridge', 'Queenstown', 'Levin'
];

const categories = [
    'Painting Jobs',
    'Interior Work',
    'Exterior Work',
    'Roofing',
    'Landscaping',
    'Plumbing',
];

const PostJob: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const { status, error, job } = useSelector((state: RootState) => state.jobs);

    const [formData, setFormData] = useState({
        title: '',
        category: categories[0],
        location: '',
        budget: '',
        description: '',
        images: [] as File[],
    });
    const [isOpen, setIsOpen] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImagesChange = (newImages: File[]) => {
        setFormData({ ...formData, images: newImages });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData();
        data.append('title', formData.title);
        data.append('category', formData.category);
        data.append('location', formData.location);
        data.append('budget', formData.budget);
        data.append('description', formData.description);

        formData.images.forEach((image) => {
            data.append('images', image);
        });

        try {
            await dispatch(postJob(data)).unwrap();
            setIsOpen(true);
        } catch (error) {
            console.error('Error posting job:', error);
        }
    };




    const goToJobDetail = () => {
        setIsOpen(false);
        if (job) navigate(`/jobs/${job.slug}`);
    };

    return (
        <div className="font-sans">
            <DefaultTitle title="Post a project!" subTitle="Need something done? Post your project now." />
            <div className="container mx-auto px-8 lg:px-18 py-6">
                <div className="grid grid-cols-2">
                    <div>
                        <form onSubmit={handleSubmit} className="w-full">
                            <div className="mb-4">
                                <label htmlFor="title" className="block text-md mb-2 font-medium">Title</label>
                                <input
                                    type="text"
                                    id="title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="category" className="block text-md mb-2 font-medium">Category</label>
                                <select
                                    id="category"
                                    name="category"
                                    value={formData.category}
                                    onChange={handleChange}
                                    className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                >
                                    {categories.map((category, index) => (
                                        <option key={index} value={category}>{category}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="location" className="block text-md mb-2 font-medium">Location</label>
                                <select
                                    id="location"
                                    name="location"
                                    value={formData.location}
                                    onChange={handleChange}
                                    className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                >
                                    <option value="">Select a city</option>
                                    {cities.map((city) => (
                                        <option key={city} value={city}>{city}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-4">
                                <label htmlFor="budget" className="block text-md mb-2 font-medium">Budget $</label>
                                <input
                                    type="number"
                                    id="budget"
                                    name="budget"
                                    value={formData.budget}
                                    onChange={handleChange}
                                    className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="description" className="block text-md mb-2 font-medium">Description</label>
                                <textarea
                                    rows={6}
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    className="w-full p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                                ></textarea>
                            </div>
                            <div className="mb-6">
                                <label htmlFor="images" className="block text-md mb-2 font-medium">Images</label>
                                <ImageUpload images={formData.images} onImageChange={handleImagesChange} />
                            </div>
                            <button
                                type="submit"
                                className="px-14 py-4 bg-primary text-white font-bold rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary-dark"
                                disabled={status === 'loading'}
                            >
                                {status === 'loading' ? 'Posting...' : 'Post Now'}
                            </button>
                            {error && <p className="text-red-600 mt-4">Error: {error}</p>}

                        </form>
                    </div>
                </div>
            </div>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed inset-0 z-50 flex items-center justify-center">
                <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
                <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                    <div className="text-center">
                        <h2 className="text-xl font-extrabold mb-2">Congratulations! 🎉</h2>
                        <p className="font-bold">Your Job is Posted!</p>
                        <div className="py-8">
                            <img className="w-16 mx-auto" src={blueTickMark} alt="Success" />
                        </div>
                        <button
                            onClick={goToJobDetail}
                            type="button"
                            className="px-14 py-4 bg-primary text-white font-bold rounded-2xl hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
                        >
                            View Job
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default PostJob;
