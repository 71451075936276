import React from 'react';
import { useAppSelector } from '../redux/hooks';
import { Navigate } from 'react-router-dom';

const PublicRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { token } = useAppSelector((state) => state.auth);

  return token ? <Navigate to="/profile" /> : children;
};

export default PublicRoute;
