import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import config from '../../config/config';

interface BusinessDetails {
    images: string[];
    legalBusinessName: string;
    nzbn: string;
    businessLocation: string;
    businessFields: string;
    bankAccountNumber: string;
}

interface CompanyDetail {
    rating: number;
    contactNumber: string;
    id: number;
    username: string;
    userType: string;
    businessDetails: BusinessDetails;
}

interface CompanyDetailState {
    companyDetail: CompanyDetail | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

interface CompanyDetailErrorResponse {
    error: string;
}

export const fetchCompanyDetail = createAsyncThunk(
    'companyDetail/fetchCompanyDetail',
    async (username: string, thunkAPI) => {
        try {
            const response = await axios.get<CompanyDetail>(`${config.apiBaseUrl}/companies/${username}`);
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<CompanyDetailErrorResponse>;
            return thunkAPI.rejectWithValue(axiosError.response?.data?.error || 'An error occurred');
        }
    }
);

const initialState: CompanyDetailState = {
    companyDetail: null,
    status: 'idle',
    error: null,
};

const companyDetailSlice = createSlice({
    name: 'companyDetail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyDetail.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchCompanyDetail.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.companyDetail = action.payload;
            })
            .addCase(fetchCompanyDetail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export default companyDetailSlice.reducer;
