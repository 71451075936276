import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { updateProfile } from '../../../features/auth/authSlice';

interface UserProfile {
    username: string;
    email: string;
    contactNumber: string;
    password: string;
}

interface EditProfileModalProps {
    user: {
        username: string;
        email: string;
        contactNumber: string;
    };
    onClose: () => void;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({ user, onClose }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState({
        username: user.username,
        email: user.email,
        contactNumber: user.contactNumber,
        password: '',
    });
    const [error, setError] = useState<string | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        // Filter out empty fields
        const filteredData = Object.fromEntries(
            Object.entries(formData).filter(([_, value]) => value.trim() !== "")
        ) as Partial<UserProfile>;

        try {
            await dispatch(updateProfile(filteredData)).unwrap();
            onClose();
        } catch (err) {
            setError(typeof err === 'string' ? err : JSON.stringify(err));
        }
    };



    return (
        <Dialog open={true} onClose={onClose} className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true"></div>
            <div className="relative bg-white rounded-2xl shadow-lg p-8 max-w-lg w-full mx-auto">
                <h2 className="text-xl font-extrabold mb-4">Edit Profile</h2>
                {error && <p className="text-red-600 mb-4">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-md mb-2 font-medium">Username</label>
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-md mb-2 font-medium">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-md mb-2 font-medium">Contact Number</label>
                        <input
                            type="text"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleChange}
                            className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-md mb-2 font-medium">Password</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="w-full max-w-md p-3 shadow-md mb-4 rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary"
                        />
                    </div>
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 font-bold text-gray-600 rounded-md bg-gray-200"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 font-bold text-white rounded-md bg-primary hover:bg-primary-dark"
                        >
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    );
};

export default EditProfileModal;
