import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import config from '../../config/config';

interface JobDetail {
    id: number;
    title: string;
    slug: string;
    category: string;
    location: string;
    budget: number;
    description: string;
    images: string[];
    status: string;
    specialInstructions: string;
    userId: number;
    hiredBidderId: number,
    user: {
        id: number;
        username: string;
        email: string;
        contactNumber: string;
        userType: string;
    };
    createdAt: string;
    updatedAt: string;
}

interface JobDetailState {
    jobDetail: JobDetail | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

interface JobDetailErrorResponse {
    error: string;
}

export const fetchJobDetail = createAsyncThunk(
    'jobDetail/fetchJobDetail',
    async (slug: string, thunkAPI) => {
        try {
            const response = await axios.get<JobDetail>(`${config.apiBaseUrl}/projects/${slug}`);
            return response.data;
        } catch (error) {
            const axiosError = error as AxiosError<JobDetailErrorResponse>;
            return thunkAPI.rejectWithValue(axiosError.response?.data?.error || 'An error occurred');
        }
    }
);

const initialState: JobDetailState = {
    jobDetail: null,
    status: 'idle',
    error: null,
};

const jobDetailSlice = createSlice({
    name: 'jobDetail',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchJobDetail.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchJobDetail.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.jobDetail = action.payload;
            })
            .addCase(fetchJobDetail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export default jobDetailSlice.reducer;
