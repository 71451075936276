import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import config from '../../config/config';

interface User {
    username: string;
    email: string;
    contactNumber: string;
    password: string;
    confirmPassword: string;
    image?: string;
}

interface UserResponse {
    id: number;
    username: string;
    email: string;
    contactNumber: string;
    createdAt: string;
    userType: string;
    image: string;
}

interface UserState {
    user: UserResponse | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

interface RegisterSuccessResponse {
    message: string;
    user: UserResponse;
}

interface RegisterErrorResponse {
    error: string;
}

export const registerUser = createAsyncThunk(
    'user/registerUser',
    async (userData: User, thunkAPI) => {
        try {
            const response = await axios.post<RegisterSuccessResponse>(`${config.apiBaseUrl}/auth/register`, userData);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError<RegisterErrorResponse>;
                return thunkAPI.rejectWithValue(axiosError.response?.data?.error || 'An error occurred');
            } else {
                return thunkAPI.rejectWithValue('An unknown error occurred');
            }
        }
    }
);

export const uploadUserImage = createAsyncThunk(
    'user/uploadUserImage',
    async (formData: FormData, { rejectWithValue }) => {
        try {
            const response = await axios.put(`${config.apiBaseUrl}/user/image`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data.user;
        } catch (error) {
            const axiosError = error as AxiosError;
            return rejectWithValue(axiosError.response?.data || 'An error occurred');
        }
    }
);

const initialState: UserState = {
    user: null,
    status: 'idle',
    error: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.user = action.payload.user;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            })
            .addCase(uploadUserImage.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(uploadUserImage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (state.user) {
                    state.user.image = action.payload.image;
                }
            })
            .addCase(uploadUserImage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export default userSlice.reducer;
